// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"src/entry.client.tsx"
);
import.meta.hot.lastModified = "1715264889008.3628";
}
// REMIX HMR END

import { hydrateRoot } from 'react-dom/client';
import { startTransition, StrictMode } from "react";
import { RemixBrowser } from '@remix-run/react';
import { LocaleContextProvider } from './providers/LocaleProvider';

const locales = [...window.navigator.languages];


// `startTransition` keeps React hydration from blocking the main thread, this
// is useful in a server-rendered app like Remix because the UI is already on
// the page the user could start scrolling. If you have a very large document
// hydration could take hundreds of milliseconds, and make the scroll janky.
// `startTransition` keeps the main thread from blocking so the user can scroll
// while React hydrates without jank.
startTransition(() => {
  // Notice that this is your own application code that calls `hydrateRoot`
  hydrateRoot(
    document,
    <StrictMode>
      <LocaleContextProvider locales={locales}>
        <RemixBrowser />
      </LocaleContextProvider>  
    </StrictMode>
  );
});
